import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NOT_FOUND_BANNER } from '@shared/constants/assets.const';
import { SmallScreenService } from '../shared/small-screen.service';
import {
  bannerImages,
  errorMessages,
  pageTitles,
} from './page-error-messages.const';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  notFoundBannerSrc = NOT_FOUND_BANNER;
  errorMessage = '';
  pageTitle = '';
  bannerImageSrc = '';

  constructor(
    public smallScreenService: SmallScreenService,
    private activatedRouteSnapshot: ActivatedRoute
  ) {
    const { url } = this.activatedRouteSnapshot.snapshot;
    const [urlSegment] = url || [];
    this.errorMessage = errorMessages[urlSegment.path];
    this.pageTitle = pageTitles[urlSegment.path];
    this.bannerImageSrc = bannerImages[urlSegment.path];
  }

  ngOnInit(): void {}
}
