import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, canActivate } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import {
  isAdmin,
  redirectLoggedInToRoot,
  redirectUnauthorizedToLogin,
} from '@shared/utils/routing.utils';
import { ActionsRedirectionComponent } from '@core/actions-redirection/actions-redirection.component';
import { CoreComponent } from '@core/core.component';
import { NewPasswordComponent } from '@core/new-password/new-password.component';
import { NotFoundComponent } from '@core/not-found/not-found.component';
import { ActionCodeValidatorGuard } from '@core/shared/action-code-validator.guard';
import { SignInComponent } from '@core/sign-in/sign-in.component';

const tempAppRoutes: Routes = [
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then((m) => m.MenuModule),
  },
  {
    path: 'documentos',
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'equivalentes',
    loadChildren: () =>
      import('./equivalencies/equivalencies.module').then(
        (m) => m.EquivalenciesModule
      ),
  },
  {
    path: 'marcas',
    loadChildren: () =>
      import('./brands/brands.module').then((m) => m.BrandsModule),
  },
];

const routes: Routes = [
  {
    path: 'inicia-sesion',
    component: SignInComponent,
    ...canActivate(redirectLoggedInToRoot),
  },
  {
    path: 'nueva-contrasena',
    component: NewPasswordComponent,
    canActivate: [AngularFireAuthGuard, ActionCodeValidatorGuard],
    data: {
      authGuardPipe: redirectLoggedInToRoot,
    },
  },
  {
    path: 'acciones',
    component: ActionsRedirectionComponent,
    canActivate: [AngularFireAuthGuard, ActionCodeValidatorGuard],
    data: {
      authGuardPipe: redirectLoggedInToRoot,
    },
  },
  {
    path: '',
    component: CoreComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/pacientes',
      },
      {
        path: 'pacientes',
        ...canActivate(isAdmin),
        loadChildren: () =>
          import('./patients/patients.module').then((m) => m.PatientsModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      ...tempAppRoutes,
    ],
  },
  { path: 'link-expirado', component: NotFoundComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
