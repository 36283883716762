<mat-toolbar>
  <span *ngIf="isSmallScreen && withMenu" class="menu">
    <button mat-icon-button (click)="menuToggle.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  </span>
  <span class="image">
    <img [src]="logoSrc | generateAssetLink" alt="logo" />
  </span>
  <span class="spacer"></span>
  <span *ngIf="withProfile" class="user-actions" [matMenuTriggerFor]="userMenu">
    <span class="user-image">
      <img
        [src]="profileImgSrc | generateAssetLink"
        alt="user profile picture"
      />
    </span>
    <mat-icon>arrow_drop_down</mat-icon>
  </span>
</mat-toolbar>
<div class="colored-shadow">
  <span class="colored-shadow__pink"></span
  ><span class="colored-shadow__yellow"></span
  ><span class="colored-shadow__green"></span>
</div>

<mat-menu #userMenu>
  <button mat-menu-item [routerLink]="['/', 'admin', 'perfil']">Perfil</button>
  <button mat-menu-item (click)="signOut()">Cerrar Sesión</button>
</mat-menu>
