<app-main-toolbar
  [withMenu]="false"
  [isSmallScreen]="smallScreenService.isMediumScreen"
></app-main-toolbar>

<div class="content">
  <h1>Cambia tu contraseña</h1>
  <p class="description">
    Ingresa una nueva contraseña para tu cuenta. Debe incluir al menos 8
    caracteres, una letra y un número.
  </p>

  <form class="new-password" [formGroup]="newPasswordFG">
    <mat-form-field>
      <mat-label>Nueva Contraseña</mat-label>
      <input
        matInput
        [formControlName]="pOne"
        [type]="passwordInputType[pOne]"
        (keyup)="onEnter($event)"
        required
      />
      <mat-error *ngIf="newPasswordFG.controls[pOne].getError('required')">
        Introduce contraseña
      </mat-error>
      <mat-error *ngIf="newPasswordFG.controls[pOne].getError('pattern')">
        La contraseña no cumple con la criteria establecida
      </mat-error>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Show Password"
        (click)="togglePasswordVisibility($event, pOne)"
        (keydown)="$event.code === 'enter' ? $event.stopPropagation() : ''"
      >
        <mat-icon>{{
          passwordInputType[pOne] === 'password'
            ? 'visibility'
            : 'visibility_off'
        }}</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Confirma Contraseña</mat-label>
      <input
        matInput
        [formControlName]="pTwo"
        [type]="passwordInputType[pTwo]"
        (keyup)="onEnter($event)"
        required
      />
      <mat-error *ngIf="newPasswordFG.controls[pTwo].getError('required')">
        Introduce contraseña
      </mat-error>
      <mat-error *ngIf="newPasswordFG.controls[pTwo].getError('mismatch')">
        Las contraseñas no coinciden
      </mat-error>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Show Password"
        (click)="togglePasswordVisibility($event, pTwo)"
        (keydown)="$event.code === 'enter' ? $event.stopPropagation() : ''"
      >
        <mat-icon>{{
          passwordInputType[pTwo] === 'password'
            ? 'visibility'
            : 'visibility_off'
        }}</mat-icon>
      </button>
    </mat-form-field>

    <button
      mat-flat-button
      class="submit"
      color="primary"
      [disabled]="!newPasswordFG.valid"
      (click)="changePassword()"
    >
      Cambiar Contraseña
    </button>
  </form>
</div>
