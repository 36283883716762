import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldConfiguration } from '@shared/components';
import { USER_INFO } from '@shared/constants/storage-keys.const';
import { DataObject, MansanoUser, UserType } from '@shared/models';
import { StorageService } from '@shared/services/storage.service';

@Injectable()
export class ConfigurableFormFieldService {
  constructor(
    private fb: FormBuilder,
    private storageService: StorageService
  ) {}

  generateUserFG(
    formConfiguration: FieldConfiguration[],
    dataSource: DataObject = {}
  ): FormGroup {
    const currentUser = this.storageService.getItem<MansanoUser>(USER_INFO);
    const { userType } = currentUser;
    const isAdmin = userType === UserType.Admin;

    let fgObject = {};
    for (const field of formConfiguration) {
      const { validators } = field;
      const processValidators =
        typeof validators === 'function' ? validators(isAdmin) : validators;
      fgObject = {
        ...fgObject,
        [field.formControlName]: [
          dataSource[field.formControlName] || '',
          processValidators || [],
        ],
      };
    }
    return this.fb.group(fgObject);
  }

  generateControlName(str: string): string {
    const strWords = str.split(' ');
    const mappedWords = strWords.map((word, index) => {
      const trimmedWord = word.trim();
      const firstChar = trimmedWord[0];
      const firstCharModification =
        index === 0 ? firstChar.toLowerCase() : firstChar.toUpperCase();
      return `${firstCharModification}${trimmedWord.substring(1)}`;
    });
    return mappedWords.join('');
  }
}
