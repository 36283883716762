import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  LOGO_IMAGE_SOURCE,
  PROFILE_DEFAULT,
} from '@shared/constants/assets.const';
import { AngularFireAuth } from '@angular/fire/auth';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SignInService } from '../../sign-in/sign-in.service';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent implements OnInit, OnDestroy {
  @Input() isSmallScreen = false;
  @Input() withMenu = true;
  @Input() withProfile = true;
  @Output() menuToggle = new EventEmitter();

  logoSrc = LOGO_IMAGE_SOURCE;
  profileImgSrc = PROFILE_DEFAULT;

  private unsubscribeAll = new Subject();

  constructor(
    private auth: AngularFireAuth,
    private signInService: SignInService,
    private router: Router,
    private storage: AngularFireStorage
  ) {}

  ngOnInit(): void {
    const currentUser$ = from(this.auth.currentUser).pipe(
      takeUntil(this.unsubscribeAll)
    );
    const photoURL$ = currentUser$.pipe(
      switchMap((currentUser) => {
        this.withProfile = currentUser != null;
        let downloadURL$: Observable<any> = EMPTY;
        if (currentUser != null && currentUser.photoURL != null) {
          const { photoURL } = currentUser;
          downloadURL$ = this.storage.ref(photoURL).getDownloadURL();
        }
        return downloadURL$;
      })
    );

    photoURL$.subscribe((downloadURL) => {
      this.profileImgSrc = downloadURL;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  signOut(): void {
    this.signInService.signOut().subscribe(() => {
      this.router.navigate(['/', 'inicia-sesion']);
    });
  }
}
