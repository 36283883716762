import { Pipe, PipeTransform } from '@angular/core';
import { RequiredFunction } from '@shared/components';
import { USER_INFO } from '@shared/constants/storage-keys.const';
import { MansanoUser, UserType } from '@shared/models';
import { StorageService } from '@shared/services/storage.service';

@Pipe({
  name: 'isFieldRequired',
})
export class IsFieldRequiredPipe implements PipeTransform {
  constructor(private storageService: StorageService) {}

  transform(value: boolean | RequiredFunction): boolean {
    const currentUser = this.storageService.getItem<MansanoUser>(USER_INFO);
    const { userType } = currentUser;
    const isAdmin = userType === UserType.Admin;
    return typeof value === 'function' ? value(isAdmin) : value;
  }
}
