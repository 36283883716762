import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SCREEN_MEDIUM, SCREEN_SMALL } from '@shared/constants/layout.const';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

function maxWidthQueryGenerator(screenSize: string): string {
  return `(max-width: ${screenSize})`;
}

@Injectable({ providedIn: 'root' })
export class SmallScreenService {
  static get MAX_WIDTH_QUERY_MEDIUM(): string {
    return maxWidthQueryGenerator(SCREEN_MEDIUM);
  }
  static get MAX_WIDTH_QUERY_SMALL(): string {
    return maxWidthQueryGenerator(SCREEN_SMALL);
  }

  /** flag that's true if the viewport is smaller than {@link SCREEN_MEDIUM} */
  isMediumScreen: boolean;

  get isSmallScreen$(): Observable<boolean> {
    return this.breakpointObserver
      .observe(SmallScreenService.MAX_WIDTH_QUERY_SMALL)
      .pipe(map((res) => res.matches));
  }

  get isMediumScreen$(): Observable<boolean> {
    return this.breakpointObserver
      .observe(SmallScreenService.MAX_WIDTH_QUERY_MEDIUM)
      .pipe(map((res) => res.matches));
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isMediumScreen = this.breakpointObserver.isMatched(
      SmallScreenService.MAX_WIDTH_QUERY_MEDIUM
    );
    this.isMediumScreen$.subscribe((res) => {
      this.isMediumScreen = res;
    });
  }
}
