export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCp2UsLhrdz1tX8Pqd3moUKKBEmuzUi82o',
    authDomain: 'mansano-app-test.firebaseapp.com',
    projectId: 'mansano-app-test',
    storageBucket: 'mansano-app-test.appspot.com',
    messagingSenderId: '1047076878952',
    appId: '1:1047076878952:web:34cbc0a02c96e0760a59dd',
  },
  gCloud: {
    storageLink: 'https://storage.googleapis.com',
    beURL: 'https://mansano-be-ch2m47o2ka-uc.a.run.app',
    apiVersion: 'v1',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
