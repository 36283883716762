import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { USER_INFO } from '@shared/constants/storage-keys.const';
import { HttpMethods } from '@shared/models/http-methods.model';
import { StorageService } from '@shared/services/storage.service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ErrorCode, MansanoUser } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class SignInService extends HttpMethods {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private storageService: StorageService,
    httpClient: HttpClient
  ) {
    super(httpClient);
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Observable<MansanoUser> {
    return from(
      this.angularFireAuth.signInWithEmailAndPassword(email, password)
    ).pipe(
      switchMap((userCredential) => {
        if (userCredential != null && userCredential.user != null) {
          const { user } = userCredential;

          return this.getMansanoUserData(user.uid).pipe(
            map((mansanoUser) => {
              if (mansanoUser != null) {
                const signedInUser = mansanoUser;

                this.storageService.setItem(USER_INFO, signedInUser);
                return { ...user, ...signedInUser } as MansanoUser;
              }
              throw new Error();
            }),
            catchError((e) => {
              if (e != null && e.message != null && e.message !== '') {
                return throwError(e);
              }
              return throwError(ErrorCode.UserDataNotFound);
            })
          );
        }
        return throwError(new Error(ErrorCode.Unknown));
      })
    );
  }

  signOut(): Observable<any> {
    const signOut$ = from(this.angularFireAuth.signOut());
    return signOut$.pipe(take(1));
  }

  getMansanoUserData(uid: string): Observable<MansanoUser> {
    const uri = `/user/${uid}`;
    return this.get(uri);
  }
}
