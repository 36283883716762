import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingBarService {
  public loading = false;

  setToLoading(): void {
    this.loading = true;
  }

  setToNotLoading(): void {
    this.loading = false;
  }

  isLoading(): boolean {
    return this.loading;
  }

  toggle(): void {
    this.loading = !this.loading;
  }
}
