import { enableProdMode, Injector, NgModule } from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@environment';
import { CoreModule } from '@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PERSISTENCE } from '@angular/fire/auth';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    CoreModule,
  ],
  providers: [
    {
      provide: PERSISTENCE,
      useValue: 'local',
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        maxWidth: '800px',
        autoFocus: false,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

if (environment.production) {
  enableProdMode();
}
export let appInjector: Injector;
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((componentRef) => {
    appInjector = componentRef.injector;
  })
  .catch((err) => console.error(err));
