<form class="password-reset__form">
  <p>
    Si el correo electrónico existe, te enviaremos un mensaje para que puedas
    restablecer tu contraseña
  </p>
  <mat-form-field>
    <mat-label>Correo Electrónico</mat-label>
    <input matInput [formControl]="resetPassEmail" type="text" required />
    <mat-error *ngIf="resetPassEmail.getError('email')">
      El formato de tu correo es incorrecto
    </mat-error>
    <mat-error *ngIf="resetPassEmail.getError('required')">
      Introduce correo electrónico
    </mat-error>
  </mat-form-field>
  <button
    mat-flat-button
    class="submit"
    color="primary"
    [disabled]="resetPassEmail.invalid"
    (click)="resetPassword()"
  >
    Restablecer Contraseña
  </button>
  <a class="inner-nav-link" (click)="this.signIn.emit()"> Inicia Sesión </a>
</form>
