export const LOGO_IMAGE_SOURCE = '/assets/logo_fullcolor.png';
export const SIGN_IN_BANNER = '/assets/sign_in.jpg';
export const NOT_FOUND_BANNER = '/assets/error_not_found.jpg';
export const EXPIRED_LINK_BANNER = '/assets/error_expired_link.jpg';
export const PROFILE_DEFAULT = '/assets/profile_default.png';

// Menu icons
export const MENU_ICON_MENU = '/assets/menu_menu.svg';
export const MENU_ICON_DOCUMENTS = '/assets/menu_documents.svg';
export const MENU_ICON_EQUIVALENTS = '/assets/menu_equivalents.svg';
export const MENU_ICON_BRANDS = '/assets/menu_brands.svg';
export const MENU_ICON_EXERCISES = '/assets/menu_exercise.svg';
export const MENU_ICON_PATIENTS = '/assets/menu_patients.svg';
export const MENU_ICON_ADMIN = '/assets/menu_admin.svg';

// Action icons
export const ACTION_ASSIGN = 'assets/action_user_add.svg';
export const ACTION_UNASSIGN = 'assets/action_user_remove.svg';
export const ACTION_DELETE = 'assets/action_delete.svg';
export const ACTION_UPLOAD = 'assets/action_upload.svg';
export const ACTION_DOWNLOAD = 'assets/action_download.svg';
