import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  getItem<T = any>(key: string): T {
    const item = localStorage.getItem(key);

    if (item != null) {
      return JSON.parse(item) as T;
    }
    throw new Error('Item not available on window storage');
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
