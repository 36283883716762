<mat-progress-bar
  mode="indeterminate"
  *ngIf="loadingBarService.loading"
></mat-progress-bar>

<app-main-toolbar
  [isSmallScreen]="smallScreenService.isMediumScreen"
  (menuToggle)="onMenuToggle()"
></app-main-toolbar>

<mat-sidenav-container>
  <mat-sidenav fixedInViewport fixedTopGap="85" [autoFocus]="false">
    <button mat-button routerLink="/menu" (click)="onNavButtonClick()">
      <img
        class="icon"
        [src]="menuIconMenu | generateAssetLink"
        alt="menu-icon"
      />
      Menu
    </button>
    <button mat-button routerLink="/documentos" (click)="onNavButtonClick()">
      <img
        class="icon"
        [src]="menuIconDocuments | generateAssetLink"
        alt="menu-icon"
      />
      Documentos
    </button>
    <button mat-button routerLink="/equivalentes" (click)="onNavButtonClick()">
      <img
        class="icon"
        [src]="menuIconEquivalents | generateAssetLink"
        alt="menu-icon"
      />
      Equivalentes
    </button>
    <button mat-button routerLink="/marcas" (click)="onNavButtonClick()">
      <img
        class="icon"
        [src]="menuIconBrands | generateAssetLink"
        alt="menu-icon"
      />
      Marcas
    </button>
    <button
      *ngIf="isAdmin"
      mat-button
      routerLink="/ejercicios"
      (click)="onNavButtonClick()"
    >
      <img
        class="icon"
        [src]="menuIconExercises | generateAssetLink"
        alt="menu-icon"
      />
      Ejercicios
    </button>
    <button
      *ngIf="isAdmin"
      mat-button
      routerLink="/pacientes"
      (click)="onNavButtonClick()"
    >
      <img
        class="icon"
        [src]="menuIconPatients | generateAssetLink"
        alt="menu-icon"
      />
      Pacientes
    </button>

    <button
      *ngIf="isAdmin"
      mat-button
      routerLink="/admin"
      (click)="onNavButtonClick()"
    >
      <img
        class="icon"
        [src]="menuIconAdmin | generateAssetLink"
        alt="menu-icon"
      />
      Admin
    </button>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="breadcrumbs">
      <!-- TODO: implement breadcrumbs Usuarios / Crear Cuenta Nueva -->
    </div>
    <div class="main-content">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
