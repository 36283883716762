import {
  EXPIRED_LINK_BANNER,
  NOT_FOUND_BANNER,
} from '@shared/constants/assets.const';

export const errorMessages: { [urlPath: string]: string } = {
  404: 'Lo sentimos, pero la página solicitada no ha sido encontrada',
  'link-expirado':
    'Este link para crear una nueva contraseña ha expirado. Puedes intentar iniciar sesión o solicitar restablecer tu contraseña',
};

export const pageTitles: { [urlPath: string]: string } = {
  404: 'Página 404',
  'link-expirado': 'Link expirado',
};

export const bannerImages: { [urlPath: string]: string } = {
  404: NOT_FOUND_BANNER,
  'link-expirado': EXPIRED_LINK_BANNER,
};
