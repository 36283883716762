import {
  Component,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  FieldConfiguration,
  InputType,
} from '@shared/components/configurable-form-field/configurable-form-field.model';
import { ControlContainer, FormControl } from '@angular/forms';

@Component({
  selector: 'app-configurable-form-field',
  templateUrl: './configurable-form-field.component.html',
  styleUrls: ['./configurable-form-field.component.scss'],
})
export class ConfigurableFormFieldComponent implements OnInit {
  @Input() formControlName?: string;
  @Input() fieldConfiguration?: FieldConfiguration;
  @Input() control: FormControl = new FormControl();

  inputTypeEnum = InputType;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {}
}
