import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function confirmPasswordValidatorFactory(
  pOne: string,
  pTwo: string
): ValidatorFn {
  return function confirmPasswordValidator(
    abstractControl: AbstractControl
  ): ValidationErrors | null {
    const formGroup = abstractControl as FormGroup;
    const pOneFC = formGroup.controls[pOne];
    const pTwoFC = formGroup.controls[pTwo];

    const pOneVal = pOneFC.value;
    const pTwoVal = pTwoFC.value;

    const error: ValidationErrors | null =
      pOneVal !== pTwoVal ? { mismatch: true } : null;

    pTwoFC.setErrors(error);
    return null;
  };
}
