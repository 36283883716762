import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { CoreComponent } from './core.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MainToolbarComponent } from './main-navigation/main-toolbar/main-toolbar.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { PasswordResetComponent } from './sign-in/password-reset/password-reset.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ActionsRedirectionComponent } from './actions-redirection/actions-redirection.component';

@NgModule({
  declarations: [
    CoreComponent,
    MainNavigationComponent,
    NotFoundComponent,
    MainToolbarComponent,
    SignInComponent,
    PasswordResetComponent,
    NewPasswordComponent,
    ActionsRedirectionComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class CoreModule {}
