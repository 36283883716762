<app-main-toolbar
  [withMenu]="false"
  [isSmallScreen]="smallScreenService.isMediumScreen"
></app-main-toolbar>

<div
  class="content"
  [ngClass]="smallScreenService.isMediumScreen ? 'small-screen' : ''"
>
  <div *ngIf="!smallScreenService.isMediumScreen" class="banner-container">
    <img [src]="signInBannerSrc | generateAssetLink" alt="banner image" />
  </div>
  <div class="sign-in">
    <h1 class="welcome">Bienvenido a Mansano Nutrición</h1>
    <ng-container
      *ngIf="needResetPassword; then signInForm; else passwordRest"
    ></ng-container>
  </div>
</div>

<ng-template #signInForm>
  <form [formGroup]="signInFG" class="sign-in__form">
    <mat-form-field>
      <mat-label>Correo Electrónico</mat-label>
      <input
        matInput
        formControlName="email"
        type="text"
        (keyup)="onEnter($event)"
        required
      />
      <mat-error *ngIf="signInFG.controls.email.getError('email')">
        El formato de tu correo es incorrecto
      </mat-error>
      <mat-error *ngIf="signInFG.controls.email.getError('required')">
        Introduce correo electrónico
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Contraseña</mat-label>
      <input
        matInput
        formControlName="password"
        [type]="passwordInputType"
        (keyup)="onEnter($event)"
        required
      />
      <mat-error *ngIf="signInFG.controls.password.getError('required')">
        Introduce contraseña
      </mat-error>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Show Password"
        (click)="togglePasswordVisibility($event)"
        (keydown)="$event.code === 'enter' ? $event.stopPropagation() : ''"
      >
        <mat-icon>{{
          passwordInputType === 'password' ? 'visibility' : 'visibility_off'
        }}</mat-icon>
      </button>
    </mat-form-field>
    <button
      mat-flat-button
      class="submit"
      color="primary"
      [disabled]="signInFG.invalid"
      (click)="signIn()"
    >
      Iniciar Sesión
    </button>
    <a class="inner-nav-link" (click)="recoverPassword()">
      ¿Olvidaste tu contraseña?
    </a>
  </form>
</ng-template>
<ng-template #passwordRest>
  <form>
    <app-password-reset (signIn)="recoverPassword()"></app-password-reset>
  </form>
</ng-template>
