import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'executeCondition',
  pure: false,
})
export class ExecuteConditionPipe implements PipeTransform {
  transform(
    formControl: FormControl | null,
    condition: (formControl: FormControl | null) => boolean = () => true
  ): boolean {
    return condition(formControl);
  }
}
