import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthorizationInterceptorService } from '@core/shared/authorization-interceptor.service';
import { ConfigurableFormFieldComponent } from '@shared/components';
import { IsFieldRequiredPipe } from '@shared/components/configurable-form-field/is-field-required.pipe';
import { ExecuteConditionPipe } from './components/configurable-form-field/execute-condition.pipe';
import { GenerateAssetLinkPipe } from './components/generate-asset-link.pipe';
import { GetFormControlPipe } from './components/get-form-control.pipe';
import { LazyImageComponent } from './components/lazy-image/lazy-image.component';

const importExports = [
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  MatIconModule,
  MatButtonModule,
  MatSidenavModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  MatSelectModule,
  MatCheckboxModule,
  MatMenuModule,
  HttpClientModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatDialogModule,
  CdkTableModule,
];

@NgModule({
  declarations: [
    ConfigurableFormFieldComponent,
    ExecuteConditionPipe,
    GetFormControlPipe,
    GenerateAssetLinkPipe,
    IsFieldRequiredPipe,
    LazyImageComponent,
  ],
  imports: [CommonModule, ...importExports],
  exports: [
    ...importExports,
    ConfigurableFormFieldComponent,
    LazyImageComponent,
    GetFormControlPipe,
    GenerateAssetLinkPipe,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptorService,
      multi: true,
    },
  ],
})
export class SharedModule {}
