export interface ErrorModel {
  code: string;
  message?: string;
}

export interface ErrorSnackbarConfig {
  message: string;
  action?: string;
}

export enum ErrorCode {
  AuthWrongPass = 'auth/wrong-password',
  AuthUserNotFound = 'auth/user-not-found',
  AuthInvalidEmail = 'auth/invalid-email',
  AuthInvalidPassword = 'auth/invalid-password',
  AuthInvalidActionCode = 'auth/invalid-action-code',
  UserDataNotFound = 'user-data-not-found',
  UserCreationFail = 'user-creation-failed',
  StorageNotFound = 'storage/object-not-found',
  StorageDownloadFailed = 'storage-download-failed',
  StorageDeleteFailed = 'storage-delete-failed',
  StorageDuplicateDocument = 'storage-duplicate-document',
  DocumentSlashOnName = 'document/slash-on-name',
  DocumentDotOnName = 'document/dot-on-name',
  DocumentMissingExtension = 'document/missing-extension',
  DocumentInvalidExtension = 'document/invalid-extension',
  BrandActionFailed = 'brand/action-failed',
  BrandMissingPatientSelection = 'brand/missing-patient-selection',
  Unknown = 'unknown',
  UnknownRequiresRefresh = 'unknownRequiresRefresh',
}
