import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'any' })
export class ActionCodeValidatorGuard implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { oobCode } = route.queryParams;
    const isActionCodeValid$ = from(this.auth.checkActionCode(oobCode));
    return isActionCodeValid$.pipe(
      map(() => true),
      catchError(() => {
        return of(this.router.createUrlTree(['/link-expirado']));
      })
    );
  }
}
