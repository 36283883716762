import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'getFormControl',
})
export class GetFormControlPipe implements PipeTransform {
  transform(
    formGroup: AbstractControl | FormGroup,
    formControlName: string
  ): FormControl {
    const castedFormGroup = formGroup as FormGroup;
    return castedFormGroup.controls[formControlName] as FormControl;
  }
}
