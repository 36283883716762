import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { filter, switchMap } from 'rxjs/operators';
import { ErrorCode } from '@shared/models';

@Injectable()
export class AuthorizationInterceptorService implements HttpInterceptor {
  static SKIP_AUTH_URLS = ['firebasestorage.googleapis.com'];

  constructor(private angularFireAuth: AngularFireAuth) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.skipAuth(req.url)) {
      return next.handle(req);
    }

    const currentUser$ = from(this.angularFireAuth.currentUser).pipe(
      filter((currentUser) => currentUser != null)
    );
    const token$ = currentUser$.pipe(
      switchMap((currentUser) => {
        if (currentUser == null) {
          return throwError(ErrorCode.UserDataNotFound);
        }
        return from(currentUser.getIdToken());
      })
    );

    const handleRequest$ = token$.pipe(
      switchMap((token) => {
        if (token == null) {
          return throwError(ErrorCode.UserDataNotFound);
        }
        const headersVals = {
          Authorization: `Bearer ${token}`,
        };
        const headers = new HttpHeaders(headersVals);
        const newRequest = req.clone({ headers });
        return next.handle(newRequest);
      })
    );
    return handleRequest$;
  }

  private skipAuth(url: string): boolean {
    return AuthorizationInterceptorService.SKIP_AUTH_URLS.some((skipUrl) => {
      return url.includes(skipUrl);
    });
  }
}
