import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class NewPasswordService {
  constructor(private auth: AngularFireAuth) {}

  changeUserPassword(
    actionCode: string,
    newPassword: string
  ): Observable<void> {
    const updatePassword$ = from(
      this.auth.confirmPasswordReset(actionCode, newPassword)
    );
    return updatePassword$;
  }
}
