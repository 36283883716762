import { FormControl, Validators } from '@angular/forms';

export enum InputType {
  Noob = 'noob',
  Text = 'text',
  Number = 'number',
  Dropdown = 'dropdown',
  Email = 'email',
  Tel = 'tel',
}

export interface DropdownOption<T = string> {
  label: string;
  value: T;
}

export interface FieldError {
  message: string;
  condition?: (formControl: FormControl) => boolean;
}

export type RequiredFunction = (isAdmin: boolean) => boolean;
export type ValidatorsFunction = (isAdmin: boolean) => Validators[];

export interface FieldConfiguration {
  inputType: InputType;
  label: string;
  formControlName: string;
  dropdownOptions?: DropdownOption<any>[];
  showDefaultOption?: boolean;
  required?: boolean | RequiredFunction;
  validators?: Validators[] | ValidatorsFunction;
  styleClass?: string;
  errorList?: FieldError[];
}
