<app-main-toolbar
  [withMenu]="false"
  [isSmallScreen]="smallScreenService.isMediumScreen"
></app-main-toolbar>

<div
  class="content"
  [ngClass]="smallScreenService.isMediumScreen ? 'small-screen' : ''"
>
  <div *ngIf="!smallScreenService.isMediumScreen" class="banner-container">
    <img [src]="bannerImageSrc | generateAssetLink" alt="banner image" />
  </div>
  <div class="not-found">
    <h1 class="not-found__title">{{ pageTitle }}</h1>
    <div class="not-found__message">{{ errorMessage }}</div>
    <a class="inner-nav-link" routerLink="/">Volver a inicio</a>
  </div>
</div>
