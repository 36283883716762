import firebase from 'firebase';
import User = firebase.User;

export enum UserType {
  Admin = 'admin',
  Patient = 'patient',
}

export enum UserGender {
  Masculine = 'masculine',
  Feminine = 'feminine',
}

export interface ContactInfo {
  firstName: string;
  lastName: string;
  userType: UserType;
}

export interface MansanoUser extends User, ContactInfo {
  distinctives?: string[];
  contact: ContactInfo;
}
