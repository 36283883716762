import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

enum FirebaseActions {
  ResetPassword = 'resetPassword',
  RecoverEmail = 'recoverEmail',
  VerifyEmail = 'verifyEmail',
}

@Component({
  selector: 'app-actions-redirection',
  template: `<p>Cargando...</p>`,
})
export class ActionsRedirectionComponent {
  constructor(activatedRoute: ActivatedRoute, private router: Router) {
    const { mode, oobCode, continueUrl } = activatedRoute.snapshot.queryParams;
    this.navigateToAction(mode, oobCode, continueUrl);
  }

  navigateToAction(
    mode: FirebaseActions,
    oobCode: string,
    continueUrl: string
  ): void {
    const navigateTo = continueUrl != null ? continueUrl : '/';
    switch (mode) {
      case FirebaseActions.ResetPassword:
        const queryParams = { oobCode, continueUrl: navigateTo };
        this.router.navigate(['/', 'nueva-contrasena'], { queryParams });
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
  }
}
