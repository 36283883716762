import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import {
  DataObject,
  ErrorCode,
  ErrorModel,
  ErrorSnackbarConfig,
} from '../models';

const ERROR_DICTIONARY: DataObject<ErrorSnackbarConfig> = {
  [ErrorCode.AuthWrongPass]: {
    message: 'Correo electrónico o contraseña incorrecta',
  },
  [ErrorCode.AuthUserNotFound]: {
    message: 'Correo electrónico o contraseña incorrecta',
  },
  [ErrorCode.AuthInvalidEmail]: {
    message: 'Correo electrónico inválido',
  },
  [ErrorCode.AuthInvalidPassword]: {
    message: 'Contraseña inválida',
  },
  [ErrorCode.AuthInvalidActionCode]: {
    message: 'El código de autenticación es inválido',
  },
  [ErrorCode.UserDataNotFound]: {
    message:
      'Ha habido un problema con tu información, favor de contactar al administrador',
  },
  [ErrorCode.UserCreationFail]: {
    message: 'No se pudo crear el usuario exitosamente.',
  },
  [ErrorCode.StorageNotFound]: {
    message:
      'No existe el archivo que deseas descargar. Favor de contactar al administrador',
  },
  [ErrorCode.StorageDownloadFailed]: {
    message:
      'Ocurrió un error al descargar el archivo. Favor de contactar al administrador',
  },
  [ErrorCode.StorageDeleteFailed]: {
    message:
      'Ocurrió un error al intentar borrar el archivo. Favor de contactar al administrador',
  },
  [ErrorCode.StorageDuplicateDocument]: {
    message: 'Uno o más archivos ya existen. Favor de eliminar duplicados.',
  },
  [ErrorCode.DocumentSlashOnName]: {
    message:
      'Ocurrió un error al subir los archivos. El nombre de uno de los archivos contiene una diagonal (/)',
  },
  [ErrorCode.DocumentDotOnName]: {
    message:
      'Ocurrió un error al subir los archivos. El nombre de uno de los archivos contiene un punto (.)',
  },
  [ErrorCode.DocumentMissingExtension]: {
    message:
      'Ocurrió un error al subir los archivos. Uno de los archivos no tiene extensión',
  },
  [ErrorCode.DocumentInvalidExtension]: {
    message:
      'Ocurrió un error al subir los archivos. Uno de los archivos contiene una extensión inválida',
  },
  [ErrorCode.BrandActionFailed]: {
    message: 'Lo sentimos, la acción que intentaste ejecutar y no es tu culpa',
  },
  [ErrorCode.BrandMissingPatientSelection]: {
    message:
      'Por favor, selecciona un paciente previo a realizar la acción seleccionada',
  },
  [ErrorCode.Unknown]: {
    message: 'Lo sentimos, ha ocurrido un error y no es tu culpa.',
  },
  [ErrorCode.UnknownRequiresRefresh]: {
    message:
      'Lo sentimos, ha ocurrido un error y no es tu culpa. Favor de refrescar la página',
  },
};

@Injectable({ providedIn: 'root' })
export class ErrorNotificationService {
  constructor(private matSnackbar: MatSnackBar) {}

  notifyAndConsole(
    errorModelOrCode: ErrorModel | ErrorCode,
    error: Error
  ): MatSnackBarRef<TextOnlySnackBar> {
    console.error(error);
    return this.notifyError(errorModelOrCode);
  }

  notifyError(
    errorModelOrCode: ErrorModel | ErrorCode
  ): MatSnackBarRef<TextOnlySnackBar> {
    const errorCode =
      typeof errorModelOrCode === 'string'
        ? errorModelOrCode
        : errorModelOrCode.code;
    const { message, action = 'OK' } =
      ERROR_DICTIONARY[errorCode] || ERROR_DICTIONARY.unknown;

    return this.matSnackbar.open(message, action);
  }
}
