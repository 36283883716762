import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment';

@Pipe({
  name: 'generateAssetLink',
})
export class GenerateAssetLinkPipe implements PipeTransform {
  transform(path: string): string {
    if (path == null || path === '') {
      throw new Error('Asset or source path not provided');
    }
    if (path.includes('http')) {
      return path;
    }
    const slash = '/';
    const { gCloud, firebase } = environment;
    const pathArray = path.split(slash);
    const cleanUriArray =
      pathArray.length > 0 && pathArray[0] === ''
        ? pathArray.slice(1)
        : pathArray;
    const cleanUri = cleanUriArray.join(slash);

    const link = `${gCloud.storageLink}/${firebase.storageBucket}/${cleanUri}`;
    return link;
  }
}
