import {
  AuthPipe,
  AuthPipeGenerator,
  customClaims,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserType } from '@shared/models';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const redirectLoggedInToRoot = () => redirectLoggedInTo(['/']);
export const redirectUnauthorizedToLogin = (
  _: ActivatedRouteSnapshot,
  routerStateSnapshot: RouterStateSnapshot
) => {
  const { url } = routerStateSnapshot;
  let redirectTo = '/inicia-sesion';
  if (url != null && url !== '') {
    redirectTo = `${redirectTo}?continueUrl=${url}`;
  }
  return redirectUnauthorizedTo(redirectTo);
};

export const isAdmin: AuthPipeGenerator = () =>
  pipe(
    customClaims,
    map((claims) => {
      return claims.userType === UserType.Admin || ['/', 'menu'];
    })
  ) as AuthPipe;
