import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, of, throwError } from 'rxjs';
import { ErrorNotificationService } from '@shared/services/error-notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorCode, ErrorModel } from '@shared/models';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
  @Output() signIn = new EventEmitter<any>();
  resetPassEmail: FormControl;

  constructor(
    formBuilder: FormBuilder,
    private auth: AngularFireAuth,
    private snackbar: MatSnackBar,
    private errorNotificationService: ErrorNotificationService
  ) {
    this.resetPassEmail = formBuilder.control(null, [
      Validators.required,
      Validators.email,
    ]);
  }

  resetPassword(): void {
    const email = this.resetPassEmail.value;
    const resetPromise = this.auth.sendPasswordResetEmail(email);
    const resetPassword$ = from(resetPromise);

    resetPassword$
      .pipe(
        catchError((error: { error: ErrorModel }) => {
          if (error?.error?.code === ErrorCode.AuthUserNotFound) {
            /*
             * Due to security reasons, if error is due to email not found,
             * we won't notify user
             */
            return of();
          }
          return throwError(error);
        })
      )
      .subscribe(
        () => {
          this.snackbar.open('Cambio de contraseña solicitado', 'OK', {
            duration: 5000,
          });
        },
        (error: ErrorModel) => {
          this.errorNotificationService.notifyError(error);
        }
      );
  }
}
