import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { USER_INFO } from '@shared/constants/storage-keys.const';
import { MansanoUser, UserType } from '@shared/models';
import { StorageService } from '@shared/services/storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SmallScreenService } from '../shared/small-screen.service';
import {
  MENU_ICON_ADMIN,
  MENU_ICON_BRANDS,
  MENU_ICON_DOCUMENTS,
  MENU_ICON_EQUIVALENTS,
  MENU_ICON_EXERCISES,
  MENU_ICON_MENU,
  MENU_ICON_PATIENTS,
} from '@shared/constants/assets.const';
import { LoadingBarService } from '@shared/services/loading-bar.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSidenav) matSidenav?: MatSidenav;
  isAdmin: boolean;

  menuIconMenu = MENU_ICON_MENU;
  menuIconDocuments = MENU_ICON_DOCUMENTS;
  menuIconEquivalents = MENU_ICON_EQUIVALENTS;
  menuIconBrands = MENU_ICON_BRANDS;
  menuIconExercises = MENU_ICON_EXERCISES;
  menuIconPatients = MENU_ICON_PATIENTS;
  menuIconAdmin = MENU_ICON_ADMIN;

  private unsubscribeAll = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private storageService: StorageService,
    public smallScreenService: SmallScreenService,
    public loadingBarService: LoadingBarService
  ) {
    const userInfo = storageService.getItem<MansanoUser>(USER_INFO);
    this.isAdmin = userInfo.userType === UserType.Admin;
  }

  ngOnInit(): void {
    this.smallScreenService.isMediumScreen$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.setMatSidenavMode();
      });
  }

  ngAfterViewInit(): void {
    // setTimeout used to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => this.setMatSidenavMode());
  }

  onMenuToggle(): void {
    if (this.smallScreenService.isMediumScreen) {
      this.matSidenav?.toggle();
    }
  }

  onNavButtonClick(): void {
    if (this.smallScreenService.isMediumScreen) {
      this.matSidenav?.close();
    }
  }

  private setMatSidenavMode(): void {
    if (this.matSidenav != null) {
      if (this.smallScreenService.isMediumScreen) {
        this.matSidenav.mode = 'over';
        this.matSidenav.opened = false;
      } else {
        this.matSidenav.mode = 'side';
        this.matSidenav.opened = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    // required by take-until-destroy
  }
}
