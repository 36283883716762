<ng-container *ngIf="fieldConfiguration != null; else missingConfiguration">
  <ng-container [ngSwitch]="fieldConfiguration.inputType">
    <ng-container
      *ngSwitchCase="inputTypeEnum.Dropdown"
      [ngTemplateOutlet]="matSelect"
      [ngTemplateOutletContext]="{ fieldConfiguration: fieldConfiguration }"
    >
    </ng-container>
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="matInput"
      [ngTemplateOutletContext]="{ fieldConfiguration: fieldConfiguration }"
    >
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #missingConfiguration>
  <p>Falta una configuración</p>
</ng-template>

<ng-template #matSelect let-fieldConfiguration="fieldConfiguration">
  <mat-form-field [class]="fieldConfiguration.styleClass || ''">
    <mat-label>{{ fieldConfiguration.label }}</mat-label>

    <mat-select
      [formControl]="control"
      [required]="fieldConfiguration.required | isFieldRequired"
    >
      <mat-option *ngIf="fieldConfiguration.showDefaultOption">--</mat-option>
      <mat-option
        *ngFor="let option of fieldConfiguration.dropdownOptions"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-error *ngFor="let error of fieldConfiguration.errorList">
      <ng-container *ngIf="control != null">
        <ng-container *ngIf="control | executeCondition: error.condition">
          {{ error.message }}
        </ng-container>
      </ng-container>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #matInput let-fieldConfiguration="fieldConfiguration">
  <mat-form-field [class]="fieldConfiguration.styleClass || ''">
    <mat-label>{{ fieldConfiguration.label }}</mat-label>

    <input
      matInput
      [formControl]="control"
      [type]="fieldConfiguration.inputType"
      [required]="fieldConfiguration.required | isFieldRequired"
    />
    <mat-error *ngFor="let error of fieldConfiguration.errorList">
      <ng-container *ngIf="control != null">
        <ng-container *ngIf="control | executeCondition: error.condition">
          {{ error.message }}
        </ng-container>
      </ng-container>
    </mat-error>
  </mat-form-field>
</ng-template>
