import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SIGN_IN_BANNER } from '@shared/constants/assets.const';
import { ErrorNotificationService } from '@shared/services/error-notification.service';
import { SmallScreenService } from '../shared/small-screen.service';
import { SignInService } from './sign-in.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  needResetPassword = true;
  signInFG: FormGroup;
  signInBannerSrc = SIGN_IN_BANNER;
  passwordInputType: 'password' | 'text' = 'password';

  private readonly continueUrl = '';

  constructor(
    fb: FormBuilder,
    private signInService: SignInService,
    private errorNotificationService: ErrorNotificationService,
    public smallScreenService: SmallScreenService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    const { continueUrl } = this.activatedRoute.snapshot.queryParams;
    this.continueUrl = continueUrl;

    this.signInFG = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  signIn(): void {
    if (this.signInFG.invalid) {
      return;
    }
    const { email, password } = this.signInFG.value;
    this.signInService.signInWithEmailAndPassword(email, password).subscribe(
      () => {
        const navigateTo =
          this.continueUrl != null && this.continueUrl !== ''
            ? this.continueUrl
            : '/';
        this.router.navigate([navigateTo]);
      },
      (error) => {
        this.errorNotificationService.notifyError(error);
      }
    );
  }

  togglePasswordVisibility(event: MouseEvent): void {
    const { x, y } = event;
    if (x === 0 && y === 0) {
      /*
       * if required as when hitting the enter key, the button received a click
       * event
       */
      return;
    }
    this.passwordInputType =
      this.passwordInputType === 'password' ? 'text' : 'password';
  }

  recoverPassword(): void {
    this.needResetPassword = !this.needResetPassword;
  }

  onEnter(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.signIn();
    }
    event.stopPropagation();
  }
}
