import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorNotificationService } from '@shared/services/error-notification.service';
import { take } from 'rxjs/operators';
import { SmallScreenService } from '../shared/small-screen.service';
import { NewPasswordService } from './new-password.service';
import { confirmPasswordValidatorFactory } from './new-password.utils';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  providers: [NewPasswordService],
})
export class NewPasswordComponent {
  pOne = 'pOne';
  pTwo = 'pTwo';
  passwordInputType: { [key: string]: 'password' | 'text' } = {
    [this.pOne]: 'password',
    [this.pTwo]: 'password',
  };
  newPasswordFG: FormGroup;

  private actionCode = '';
  private continueUrl = '';

  constructor(
    fb: FormBuilder,
    private auth: AngularFireAuth,
    private newPasswordService: NewPasswordService,
    private snackbar: MatSnackBar,
    private errorNotificationService: ErrorNotificationService,
    public smallScreenService: SmallScreenService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    const passwordPattern = '(?=.*[a-zA-Z])(?=.*\\d).{8,30}';
    this.newPasswordFG = fb.group(
      {
        [this.pOne]: [
          null,
          [Validators.required, Validators.pattern(passwordPattern)],
        ],
        [this.pTwo]: [null, [Validators.required]],
      },
      { validators: [confirmPasswordValidatorFactory(this.pOne, this.pTwo)] }
    );

    this.retrieveActionCode();
  }

  retrieveActionCode(): void {
    const { oobCode, continueUrl } = this.activatedRoute.snapshot.queryParams;
    if (oobCode == null || oobCode === '') {
      this.router.navigate(['/']);
      return;
    }

    let processedUrl = '';
    if (continueUrl != null && continueUrl !== '/') {
      // the firebase incoming continueUrl param contains a full url
      const newUrl = new URL(continueUrl);
      // therefore, we need to process it and get only the pathname
      processedUrl = newUrl.pathname;
    }

    this.actionCode = oobCode;
    this.continueUrl = processedUrl;
  }

  togglePasswordVisibility(event: MouseEvent, componentId: string): void {
    const { x, y } = event;
    if (x === 0 && y === 0) {
      /*
       * if required as when hitting the enter key, the button received a click
       * event
       */
      return;
    }
    const currentState = this.passwordInputType[componentId];
    this.passwordInputType[componentId] =
      currentState === 'password' ? 'text' : 'password';
  }

  onEnter(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.changePassword();
    }
    event.stopPropagation();
  }

  changePassword(): void {
    if (this.newPasswordFG.invalid) {
      return;
    }

    const newPassword = this.newPasswordFG.controls[this.pOne].value;
    const updatePassword$ = this.newPasswordService.changeUserPassword(
      this.actionCode,
      newPassword
    );

    updatePassword$.subscribe(
      () => {
        this.newPasswordFG.reset();
        this.newPasswordFG.disable();
        const snackbarConfig = { duration: 5000 };
        const onSnackbarAction$ = this.snackbar
          .open('Tu contraseña se cambió exitosamente', 'OK', snackbarConfig)
          .afterDismissed();
        onSnackbarAction$.pipe(take(1)).subscribe(() => {
          this.router.navigate([this.continueUrl]);
        });
      },
      (error) => {
        this.errorNotificationService.notifyAndConsole(error, error);
      }
    );
  }
}
