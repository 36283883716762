import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lazy-image',
  template: `
    <img
      *ngIf="src != null && src != ''; else imagePlaceholder"
      [class]="class"
      [src]="src"
      [alt]="alt"
    />
    <ng-template #imagePlaceholder>
      <div *ngIf="defaultImage == null" class="image-icon-placeholder">
        <mat-icon>image</mat-icon>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .image-icon-placeholder {
        background: white;
        display: inline-flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class LazyImageComponent {
  @Input() src?: string;
  @Input() alt = '';
  @Input() defaultImage?: string;
  @Input() class?: string;
}
