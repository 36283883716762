import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';

export class HttpMethods {
  constructor(private http: HttpClient) {}

  get<T = any>(uri: string, options?: { [key: string]: any }): Observable<T> {
    return this.http.get(this.genUrl(uri), options) as Observable<T>;
  }

  post<T1 = any, T2 = any>(
    uri: string,
    data: T1,
    options?: { [key: string]: any }
  ): Observable<T2> {
    return this.http.post(this.genUrl(uri), data, options) as Observable<T2>;
  }

  put<T1 = any, T2 = any>(
    uri: string,
    data: T1,
    options?: { [key: string]: any }
  ): Observable<T2> {
    return this.http.put(this.genUrl(uri), data, options) as Observable<T2>;
  }

  delete<T1 = any, T2 = any>(
    uri: string,
    data: T1,
    options?: { [key: string]: any }
  ): Observable<T2> {
    return this.http.delete(this.genUrl(uri), options) as Observable<T2>;
  }

  private genUrl(uri: string): string {
    const { gCloud } = environment;
    return `${gCloud.beURL}/${gCloud.apiVersion}${uri}`;
  }
}
